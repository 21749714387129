import axios from "axios";
import qs from "qs";
import Router from "@/router";
import { Toast } from "vant";

console.log("process.env.NODE_ENV", process.env);
const request = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

request.defaults.crossDomain = true;
request.defaults.withCredentials = true; //让ajax携带cookie

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject();
  }
);

request.interceptors.response.use(
  (response) => {
    //接收到响应数据并成功后的一些共有的处理
    if (response.data.code === 1) {
      return response.data.data;
    } else {
      console.log(response);
      if (!response.config.isMassage) {
        Toast({
          message: response.data.msg,
          icon: "cross",
        });
      }

      return Promise.reject(response.data);
    }
  },
  (error) => {
    if (error && error.response) {
      // 根据响应码具体处理
      let message = "";
      switch (error.response.status) {
        case 400:
          message = "错误请求";
          break;
        case 401:
          message = "未授权，请重新登录";
          break;
        case 403:
          message = "拒绝访问";
          break;
        case 404:
          message = "请求错误,未找到该资源";
          break;
        case 405:
          message = "请求方法未允许";
          break;
        case 408:
          message = "请求超时";
          break;
        case 500:
          message = "服务器端出错";
          break;
        case 501:
          message = "网络未实现";
          break;
        case 502:
          message = "网络错误";
          break;
        case 503:
          message = "服务不可用";
          break;
        case 504:
          message = "网络超时";
          break;
        case 505:
          message = "http版本不支持该请求";
          break;
        default:
          message = `连接错误${error.response.status}`;
      }
      Toast({
        message,
        icon: "cross",
        duration: 2 * 1000,
        onClose: () => {
          if (error.response.status === 401) {
            Router.replace("/login");
          }
        },
      });
    } else {
      // 超时处理
      if (qs.stringify(error).includes("timeout")) {
        Toast({
          message: "服务器响应超时，请刷新当前页",
          icon: "cross",
          duration: 2 * 1000,
        });
      }
      Toast({
        message: "连接服务器失败",
        icon: "cross",
        duration: 2 * 1000,
      });
    }
    return Promise.reject(error.response);
  }
);

export default request;
